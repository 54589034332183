import { useQueryClient } from "@tanstack/vue-query";
import { format } from "date-fns";
import { unref, type MaybeRef } from "vue";
import { z } from "zod";
import useNotify from "~/composables/useNotify";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  invalidateQueries,
} from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";
import { useScreeningService } from "./ScreeningService";

import type { SetCaseStatusPayload } from "~/server/api/cm/case/[id]/status.put";
import type { BulkAssignUserPayload } from "~/server/api/cm/case/bulk-assign.post";
import type { BulkCaseStatusUpdatePayload } from "~/server/api/cm/case/bulk-status-update.put";
import type {
  CaseListViewModel,
  CaseViewModel,
  DummyCaseViewModel,
  GetCasesBodyInput,
} from "~/src/models/Case/Case.model";
import type { BackendScreenResultViewModel } from "~/src/models/Case/Screen.model";
import type {
  APIPagedResponse,
  FrontendRequestObject,
} from "~/src/models/utils/Api.model";
import { InvalidationKeys } from "~/utils/queryInvalidators";

const assignment = z.enum(["unassigned", "mine", "all"]).optional();
export const listCasesRequestToQueryObject = (
  req: FrontendRequestObject
): GetCasesBodyInput => {
  return {
    page: req.page,
    assignment: assignment.parse(req.meta?.assignment),
    pageSize: req.pageSize,
    sortBy: req.sortBy,
    sortOrder: req.sortOrder,
    filters: req.filters,
    caseListOptions: req.meta?.caseListOptions,
  };
};

export const endpoints = {
  list: "/api/cm/cases",
  export: "/api/cm/cases/export",
  paging: "/api/cm/case/paging",
  get: parseUriTemplate("/api/cm/case/{id}"),
  status: {
    update: parseUriTemplate("/api/cm/case/{id}/status"),
    bulk: "/api/cm/case/bulk-status-update",
  },
  assignment: {
    post: parseUriTemplate("/api/cm/case/{id}/assignment"),
    get: parseUriTemplate("/api/cm/case/{id}/assigned-to"),
    delete: parseUriTemplate("/api/cm/case/{id}/assignment"),
    bulk: "/api/cm/case/bulk-assign",
  },
  history: {
    get: parseUriTemplate("/api/cm/case/{id}/history"),
  },
  screen: {
    get: parseUriTemplate("/api/cm/case/{id}/screen-results"),
  },
  notes: {
    post: parseUriTemplate("/api/cm/case/{id}/notes"),
  },
  dueDate: {
    update: parseUriTemplate("/api/cm/case/{id}/due-date"),
  },
} as const;

export const useCaseService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();

  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();
  const screeningService = useScreeningService();

  const cacheCase = async (caseView: CaseListViewModel) => {
    if (
      !caseView.isInspectionCase &&
      caseView.latestScreening != null &&
      caseView.previousScreenings != null
    ) {
      const setCase = caseView as CaseViewModel;
      queryClient.setQueryData(
        [QUERY_KEYS.Cases.get, caseView.caseId],
        setCase
      );
    }

    if (!caseView.isInspectionCase && caseView.latestScreening) {
      screeningService.cacheScreenResults(caseView.latestScreening);
    }
  };

  const listCases = async (body: GetCasesBodyInput, signal?: AbortSignal) => {
    const res = await $api<APIPagedResponse<CaseListViewModel>>(
      endpoints.list,
      {
        signal,
        method: "POST",
        body,
      }
    );

    for (const element of res.data) {
      cacheCase(element);
    }

    return res;
  };
  const useListCaseQuery = (
    x: MaybeRef<FrontendRequestObject>,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Cases.list, x],
      ({ signal }) => {
        const req = unref(x);
        return listCases(listCasesRequestToQueryObject(req), signal);
      },
      { createNuxtError }
    );

  const listCaseIds = async (body: GetCasesBodyInput, signal?: AbortSignal) => {
    return $api(endpoints.paging, {
      signal,
      method: "POST",
      body: body,
    });
  };
  const listCaseIdsCached = (req: FrontendRequestObject) =>
    queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.Cases.paging, req],
      queryFn: ({ signal }) => {
        return listCaseIds(listCasesRequestToQueryObject(req), signal);
      },
      staleTime: Infinity,
      retry: false,
    });
  const useListCaseIdsQuery = (
    req: MaybeRef<FrontendRequestObject | null>,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Cases.paging, req],
      ({ signal }) => {
        const _req = unref(req);
        if (!_req) return null;
        return listCaseIds(listCasesRequestToQueryObject(_req), signal);
      },
      { createNuxtError, staticData: true }
    );
  const clearCaseIdsCache = () =>
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.Cases.paging] });

  const getCaseCached = (id: string) =>
    queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.Cases.get, id],
      queryFn: ({ signal }) => getCase(id, signal),
      staleTime: Infinity,
      retry: false,
    });
  const getCase = async (id: string, signal?: AbortSignal) => {
    const res = await $api<CaseViewModel | DummyCaseViewModel>(
      endpoints.get.expand({ id: encodeURIComponent(id) }),
      {
        signal,
      }
    );
    return res;
  };
  const useGetCaseQuery = (id: MaybeRef<string>, createNuxtError = true) =>
    createQuery(
      [QUERY_KEYS.Cases.get, id],
      ({ signal }) => getCase(unref(id), signal),
      {
        createNuxtError,
        suspense: false,
      }
    );

  function getCaseExport(body: GetCasesBodyInput, signal?: AbortSignal) {
    return $api(endpoints.export, {
      signal,
      method: "POST",
      body,
    });
  }

  const setCaseStatus = (caseId: string, payload: SetCaseStatusPayload) =>
    $api(endpoints.status.update.expand({ id: caseId }), {
      method: "PUT",
      body: {
        ...payload,
      },
    });

  const useSetCaseStatusMutation = () =>
    createMutation(
      async ({
        caseId,
        values,
      }: {
        caseId: string;
        values: SetCaseStatusPayload;
      }) => {
        if (caseId === null) return null;
        return await setCaseStatus(caseId, {
          status: values.status,
          note: values.note,
          metadata: values.metadata,
        });
      },
      {
        onSuccess: (_, { caseId }) => {
          invalidateQueries(
            InvalidationKeys.Case.setStatus(caseId),
            queryClient
          );
        },
        onError: () => notifyError(t("case.error.status")),
      }
    );

  const assignCaseUser = (caseId: string, assignedTo: string) =>
    $api(endpoints.assignment.post.expand({ id: caseId }), {
      method: "POST",
      body: {
        assignedTo: assignedTo,
      },
    });

  const getAssignedTo = (caseId: string) =>
    $api(endpoints.assignment.get.expand({ id: caseId }));
  const getAllRulesFromScreenResults = (
    screenResults: BackendScreenResultViewModel
  ) => {
    const rules = new Set<{ id: string; name: string; condition: string }>();

    screenResults.hits.forEach((hit) => {
      rules.add(hit.rule);
    });

    return Array.from(rules);
  };

  const refreshCaseTable = () =>
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.Cases.list],
    });

  const useAssignCaseUserMutation = () =>
    createMutation(
      ({ caseId, assignedTo }: { caseId: string; assignedTo: string }) =>
        assignCaseUser(caseId, assignedTo),
      {
        onSuccess: (_, { caseId }) => {
          invalidateQueries(
            InvalidationKeys.Case.assignUser(caseId),
            queryClient
          );
        },
      }
    );

  const unAssignCaseUser = (caseId: string) =>
    $api(endpoints.assignment.delete.expand({ id: caseId }), {
      method: "DELETE",
    });
  const useUnAssignCaseUserMutation = () =>
    createMutation(
      async ({ caseId }: { caseId: string }) => {
        if (caseId === null) return null;
        return await unAssignCaseUser(caseId);
      },
      {
        onSuccess: (_, { caseId }) => {
          invalidateQueries(
            InvalidationKeys.Case.assignUser(caseId),
            queryClient
          );
        },
      }
    );

  const bulkAssignCaseUser = (payload: BulkAssignUserPayload) =>
    $api(endpoints.assignment.bulk, {
      method: "POST",
      body: payload,
    });
  const useBulkAssignCaseUserMutation = () =>
    createMutation(
      (payload: BulkAssignUserPayload) => bulkAssignCaseUser(payload),
      {
        onSuccess: (x, { caseIds }) => {
          invalidateQueries(
            InvalidationKeys.Case.bulkAssign(caseIds),
            queryClient
          );

          notifySuccess(
            t("general.success"),
            t("case.success.assign", { count: caseIds.length }, caseIds.length)
          );
        },
        onError: () => notifyError(t("case.error.assign")),
      }
    );

  const bulkSetCaseStatus = (payload: BulkCaseStatusUpdatePayload) =>
    $api(endpoints.status.bulk, {
      method: "PUT",
      body: payload,
    });
  const useBulkSetCaseStatusMutation = () =>
    createMutation(
      (payload: BulkCaseStatusUpdatePayload) => bulkSetCaseStatus(payload),
      {
        onSuccess: (x, { caseIds }) => {
          invalidateQueries(
            InvalidationKeys.Case.bulkStatus(caseIds),
            queryClient
          );

          notifySuccess(
            t("general.success"),
            t("case.success.status", { count: caseIds.length }, caseIds.length)
          );
        },
        onError: () => notifyError(t("case.error.assign")),
      }
    );

  const getCaseHistory = (id: string, signal?: AbortSignal) => {
    return $api(endpoints.history.get.expand({ id }), { signal });
  };
  const useGetCaseHistoryQuery = (id: string, createNuxtError = true) =>
    createQuery(
      [QUERY_KEYS.Cases.history, id],
      ({ signal }) => getCaseHistory(id, signal),
      {
        createNuxtError,
      }
    );

  const addCaseNote = (id: string, text: string) =>
    $api(endpoints.notes.post.expand({ id }), {
      method: "POST",
      body: {
        text,
      },
    });

  const useAddCaseNoteMutation = (caseId: string) =>
    createMutation((text: string) => addCaseNote(caseId, text), {
      onSuccess: () => {
        invalidateQueries(InvalidationKeys.Case.addNote(caseId), queryClient);
      },
      onError: () => notifyError(t("case.error.note")),
    });

  const updateDueDate = (id: string, date: string | null) =>
    $api(endpoints.dueDate.update.expand({ id }), {
      method: "PUT",
      body: {
        dueDate: date,
      },
    });
  const useUpdateDateMutation = () =>
    createMutation(
      async ({ caseId, date }: { caseId: string; date: Date | null }) => {
        if (caseId === null) return null;
        const val = date ? format(date, "yyyy-MM-dd") : null;
        return await updateDueDate(caseId, val);
      },
      {
        onSuccess: (_, { caseId }) => {
          invalidateQueries(InvalidationKeys.Case.addNote(caseId), queryClient);
          notifySuccess(t("general.success"), t("case.success.due_date"));
        },
        onError: () => notifyError(t("case.error.due_date")),
      }
    );

  return {
    assignCaseUser,
    bulkAssignCaseUser,
    getAllRulesFromScreenResults,
    listCases,
    listCaseIds,
    listCaseIdsCached,
    getCaseCached,
    getCaseExport,
    refreshCaseTable,
    setCaseStatus,
    getAssignedTo,
    listCasesRequestToQueryObject,
    cacheCase,
    bulkSetCaseStatus,

    useAddCaseNoteMutation,
    useAssignCaseUserMutation,
    useUnAssignCaseUserMutation,
    useBulkAssignCaseUserMutation,
    useGetCaseHistoryQuery,
    useGetCaseQuery,
    useListCaseQuery,
    useListCaseIdsQuery,
    useSetCaseStatusMutation,
    useUpdateDateMutation,
    useBulkSetCaseStatusMutation,

    clearCaseIdsCache,
  };
};
